import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useParams } from 'react-router-dom';
import '../scss/customToast.css';
import '../scss/storyForm.css';
import Loader from './Loader';
import { toast } from 'react-toastify';

function PostContentForm({ authData }) {
    const { id } = useParams();
    const token = authData.token;
    const [title, setTitle] = useState('');
    const [firstPoint, setFirstPoint] = useState('');
    const [secondPoint, setSecondPoint] = useState('');
    const [red, setRed] = useState(false);
    const [sources, setSources] = useState([]);
    const [selectedSource, setSelectedSource] = useState(null);
    const [loading, setLoading] = useState(true);
    const [slidesPerView, setSlidesPerView] = useState(4);
    const [generating, setGenerating] = useState(false);
    const [summary, setSummary] = useState('');

    useEffect(() => {
        if (!token) return;

        fetch(`https://admin-service.verita.news/generate/${id}`, {
            headers: { 'Authorization': `Bearer ${token}` },
        })
            .then(response => {
                if (response.status === 403) {
                    handleLogout();
                    return;
                }
                if (!response.ok) throw new Error('Error fetching story data');
                return response.json();
            })
            .then(data => {
                const sourcesData = data.sources.map(item => ({
                    sourceId: item.sourceId,
                    sourceName: item.sourceName,
                    sourceImageUrl: item.sourceImageUrl,
                }));
                setSources(sourcesData);
                setLoading(false);

                setTitle(data.post.title);
                setSummary(data.post.summary);

                const points = data.post.summary
                    .split('\n')
                    .filter(line => line.startsWith('->'))
                    .map(line => line.replace('->', '').trim());
                setFirstPoint(points[0] || '');
                setSecondPoint(points[1] || '');
            })
            .catch(() => {
                toast('Coś poszło nie tak', { className: 'toast-error', icon: false });
                setLoading(false);
            });
    }, [id, token]);

    useEffect(() => {
        const updateSlidesPerView = () => setSlidesPerView(window.innerWidth < 1000 ? 1 : 4);
        updateSlidesPerView();
        window.addEventListener('resize', updateSlidesPerView);
        return () => window.removeEventListener('resize', updateSlidesPerView);
    }, []);

    const handleGenerate = () => {
        if (!selectedSource) {
            toast('Proszę wybrać źródło', { className: 'toast-error', icon: false });
            return;
        }

        const payload = {
            firstPoint,
            secondPoint,
            red,
        };

        setGenerating(true);

        const fetchPromise = fetch(`https://admin-service.verita.news/generate/post-content/${selectedSource.sourceId}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        }).then(response => {
            if (response.status === 403) handleLogout();
            if (!response.ok) throw new Error('Błąd podczas generowania story');
            return response.blob();
        });

        toast.promise(fetchPromise, {
            pending: { render: 'Poczekaj chwilkę...', className: 'toast-pending', icon: true },
            success: { render: 'Pobieranie zakończone', className: 'toast-schedule', icon: false },
            error: { render: 'Coś poszło nie tak', className: 'toast-error' },
        }).then(blob => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'story.png';
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).finally(() => setGenerating(false));
    };

    const handleLogout = () => {
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('userType');
        window.location.reload();
    };

    if (loading) return <Loader />;

    return (
        <section className="storyForm-container">
            <form>
                <div className="form-element">
                    <label>Punkt pierwszy:</label>
                    <input
                        type="text"
                        value={firstPoint}
                        onChange={(e) => setFirstPoint(e.target.value)}
                        required
                    />
                </div>

                <div className="form-element">
                    <label>Punkt drugi:</label>
                    <textarea
                        value={secondPoint}
                        onChange={(e) => setSecondPoint(e.target.value)}
                    />
                </div>

                <div className="form-checkboxes">
                    <div className="checkbox-wrapper">
                        <label>Pilne - czy zmieniamy motyw na czerwony?</label>
                        <input
                            type="checkbox"
                            checked={red}
                            onChange={(e) => {
                                setRed(e.target.checked);
                            }}
                        />
                    </div>
                </div>


                <div className="swiper-container">
                    <h3>Wybierz Źródło:</h3>
                    {sources.length > 0 ? (
                        <Swiper slidesPerView={slidesPerView} spaceBetween={20}>
                            {sources.map((source, index) => (
                                <SwiperSlide key={index}>
                                    <div onClick={() => setSelectedSource(source)}>
                                        <img src={source.sourceImageUrl} alt={source.sourceName}
                                             className={`swiper-slide-content ${selectedSource === source ? 'active' : ''}`}/>
                                        <p className="source-name">{source.sourceName}</p>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    ) : <Loader/>}
                </div>

                <div className="button-container post-button-container">
                    <button type="button" onClick={() => handleGenerate('/generate-post/title')}
                            disabled={generating}>
                        Generuj
                    </button>
                </div>
            </form>

            <div className="publication-details">
                <div className="form-element">
                    <label>Tytuł Publikacji:</label>
                    <input
                        type="text"
                        value={title}
                        readOnly
                    />
                </div>

                <div className="form-element">
                    <label>Podsumowanie:</label>
                    <textarea
                        value={summary}
                        readOnly
                    ></textarea>
                </div>
            </div>
        </section>
);
}

export default PostContentForm;
