import React, {useState, useEffect} from 'react';
import '../scss/dashboard.css';
import {ReactComponent as CopyIcon} from "../img/icons/copyIcon.svg";
import {ReactComponent as TrashIcon} from "../img/icons/trashIcon.svg";
// import {ReactComponent as PencilIcon} from "../img/icons/pencilIcon.svg";
import Loader from "./Loader";
import {useNavigate} from "react-router-dom";
import '../scss/customToast.css';
import { toast } from 'react-toastify';

const Dashboard = ({authData}) => {
    const [data, setData] = useState(null);
    const token = authData.token;
    const navigate = useNavigate();
    const unpublishedPublications = data ? data.publications : [];
    // const latestPublications = data ? data.latest : [];
    const regeneratedPublications = data ? data.regenerated : [];

    const handlePublicationClick = (id) => {
        navigate(`/publication/${id}`);
    }

    // const handlePostClick = (id) => {
    //     navigate(`/post/${id}`);
    // }


    useEffect(() => {
        if (!token) {
            return;
        }

        fetch('https://admin-service.verita.news/', {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.status === 403) {
                    handleLogout();
                    return;
                }
                if (!response.ok) {
                    throw new Error('Błąd sieci');
                }
                return response.json();
            })
            .then(data => setData(data))
            .catch(error => console.error('Błąd podczas pobierania danych:', error));
    }, [token]);

    const handleLogout = () => {
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('userType');
        window.location.reload();
    };

    const handleRegeneratedPublicationClick = (id) => {
        navigate(`/regenerated/${id}`);
    }


    const handleDeleteButton = (id) => {
        fetch(`https://admin-service.verita.news/publication/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Błąd podczas usuwania publikacji');
                }
                setData(prevData => {
                    const updatedPublications = prevData.publications.filter(pub => pub.publicationId !== id);
                    return {
                        ...prevData,
                        publications: updatedPublications,
                    };
                });
                toast('Publikacja została usunięta', {
                    className: 'toast-delete',
                    icon: false,
                });
            })
            .catch(() => {
                toast('Nie udało się usunąć publikacji', {
                    className: 'toast-error',
                    icon: false,
                });
            });
    };

    const categoryData = {
        NEWS: {
            text: "Wydarzenia",
        },
        SPORT: {
            text: "Sport",
        },
        POLITICS: {
            text: "Polityka",
        },
        BUSINESSANDFINANCE: {
            text: "Biznes",
        }
    };

    const getImportance = (numberOfSources) => {
        if (numberOfSources < 9) {
            return {level: 'standard', text: 'Standardowe'};
        } else if (numberOfSources >= 10 && numberOfSources <= 16) {
            return {level: 'important', text: 'Ważne'};
        } else if (numberOfSources > 16) {
            return {level: 'urgent', text: 'Pilne'};
        } else {
            return {level: 'standard', text: 'standardowe'};
        }
    };

    const copyToClipboard = (id) => {
        navigator.clipboard.writeText(id)
            .then(() => {
                alert(`Skopiowano ID: ${id}`);
            })
            .catch(err => {
                console.error(err);
            });
    };


    const handleRegenerationDelete = (id) => {
        fetch(`https://admin-service.verita.news/regeneration/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.status === 403) {
                    handleLogout();
                    return;
                }
                if (!response.ok) {
                    toast('Coś poszło nie tak', {
                        className: 'toast-error',
                        icon: false,
                    });
                } else {
                    setData(prevData => {
                        const updatedRegenerated = prevData.regenerated.filter(pub => pub.regenerationId !== id);
                        return {
                            ...prevData,
                            regenerated: updatedRegenerated,
                        };
                    });
                    toast('Regeneracja została usunięta', {
                        className: 'toast-delete',
                        icon: false,
                    });
                }
            })
            .catch(error => console.error('Błąd podczas usuwania:', error));
    };

    // const handleGenerationClick = (id) => {
    //
    //     navigate(`/generation/${id}`);
    // }


    if (!data) {
        return (
            <div className="dashboard-loader-wrapper">
                <Loader/>
            </div>
        );
    };



    return (
        <section className="dashboard-container">
            <div className="dashboard-container-unpublished">
                <h3>Nieopublikowane publikacje</h3>
                {unpublishedPublications.length === 0 ? (
                    <ul>
                        <li className="li-brak">
                            <p className="unpublished-brak">
                                Aktualnie nie ma nowych postów do wstawienia
                            </p>
                        </li>
                    </ul>
                ) : (
                    <ul>
                        {unpublishedPublications.map((pub) => {
                            const category = pub.category;
                            const categoryInfo = categoryData[category] || { text: category, color: '#000000' };
                            const categoryClassName = `category-${category.toLowerCase()}`;

                            const numberOfSources = pub.newsBucket ? pub.newsBucket.numberOfSources : 0;
                            const importanceInfo = getImportance(numberOfSources);
                            const importanceClassName = `importance-${importanceInfo.level}`;
                            const isFirstElement = pub.publicationId === unpublishedPublications[0].publicationId;

                            return (
                                <React.Fragment key={pub.publicationId}>
                                    {isFirstElement && <div className="extra-separator"></div>}
                                    <li key={pub.publicationId} onClick={() => handlePublicationClick(pub.publicationId)}>
                                        <div className="text-flags-wrapper">
                                            <h4>{pub.title}</h4>
                                            <div className="flag-container">
                                                <span className={`category ${categoryClassName}`}>{categoryInfo.text}</span><br />
                                                <span className={`importance ${importanceClassName}`}>{importanceInfo.text}</span><br />
                                            </div>
                                        </div>
                                        <div className="dashboard-icons-wrapper">
                                            <div className="separator-small"></div>
                                            <TrashIcon
                                                className="dashboard-icon trashIcon"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleDeleteButton(pub.publicationId);
                                                }}
                                            />
                                            <CopyIcon onClick={(event) => {
                                                event.stopPropagation(pub.publicationId);
                                                copyToClipboard(pub.publicationId);
                                            }}
                                                      className="dashboard-icon copyIcon" />
                                        </div>
                                    </li>
                                    <div className="separator"></div>
                                </React.Fragment>
                            );
                        })}
                    </ul>
                )}
            </div>

            {/*<div className="dashboard-container-unpublished">*/}
            {/*    <h3>Ostatnio dodane publikacje</h3>*/}
            {/*    <ul>*/}
            {/*        {latestPublications.map((pub) => {*/}
            {/*            const category = pub.category;*/}
            {/*            const categoryInfo = categoryData[category] || {text: category, color: '#000000'};*/}
            {/*            const categoryClassName = `category-${category.toLowerCase()}`;*/}

            {/*            const numberOfSources = pub.numberOfSources || 0;*/}
            {/*            const importanceInfo = getImportance(numberOfSources);*/}
            {/*            const importanceClassName = `importance-${importanceInfo.level}`;*/}
            {/*            const isFirstElement = pub.itemId === latestPublications[0].itemId;*/}

            {/*            return (*/}
            {/*                <React.Fragment key={pub.itemId}>*/}
            {/*                    {isFirstElement && <div className="extra-separator"></div>}*/}
            {/*                    <li key={pub.itemId} onClick={() => handlePostClick(pub.itemId)}>*/}
            {/*                        <div className="text-flags-wrapper">*/}
            {/*                            <h4>{pub.title}</h4>*/}
            {/*                            <div className="flag-container">*/}
            {/*                                <span className={`category ${categoryClassName}`}>{categoryInfo.text}</span><br/>*/}
            {/*                                <span*/}
            {/*                                    className={`importance ${importanceClassName}`}>{importanceInfo.text}</span><br/>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="dashboard-icons-wrapper">*/}
            {/*                            <div className="separator-small"></div>*/}
            {/*                            <PencilIcon className="dashboard-icon pencilIcon" onClick={(event) => {*/}
            {/*                                event.stopPropagation();*/}
            {/*                                handleGenerationClick(pub.itemId);*/}
            {/*                            }}/>*/}
            {/*                            <CopyIcon*/}
            {/*                                onClick={(event) => {*/}
            {/*                                    event.stopPropagation();*/}
            {/*                                    copyToClipboard(pub.itemId);*/}
            {/*                                }}*/}
            {/*                                className="dashboard-icon copyIcon"*/}
            {/*                            />*/}
            {/*                        </div>*/}
            {/*                    </li>*/}
            {/*                    <div className="separator"></div>*/}
            {/*                </React.Fragment>*/}
            {/*            );*/}
            {/*        })}*/}
            {/*    </ul>*/}
            {/*</div>*/}
            <div className="dashboard-container-unpublished">
                <h3>Wygenerowane ponownie</h3>
                {regeneratedPublications.length === 0 ? (
                    <ul>
                        <li className="li-brak">
                            <p className="regenerated-brak">
                                Aktualnie nie ma nowych wygenerowanych ponownie postów
                            </p>
                        </li>
                        </ul>
                ) : (
                    <ul className="ul-regeneration">
                        {regeneratedPublications.map((pub, index) => {
                            const isFirstElement = index === 0;
                            return (
                                <React.Fragment key={pub.regenerationId}>
                                    {isFirstElement && <div className="extra-separator"></div>}
                                    <li className="li-regeneration" key={pub.regenerationId} onClick={() => handleRegeneratedPublicationClick(pub.regenerationId)}>
                                        <div className="text-flags-wrapper">
                                            <h4>{pub.title}</h4>
                                        </div>
                                        <div className="dashboard-icons-wrapper">
                                            <div className="separator-small"></div>
                                            <TrashIcon className="dashboard-icon trashIcon" onClick={(event) => {
                                                event.stopPropagation();
                                                handleRegenerationDelete(pub.regenerationId);
                                            }} />
                                        </div>
                                    </li>
                                    <div className="separator"></div>
                                </React.Fragment>
                            );
                        })}
                    </ul>
                )}
            </div>

        </section>
    );

};

export default Dashboard;
