import '../scss/sideMenu.css';
import logo from "../img/veritaLogo-Full-White.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as HomeIcon } from '../img/icons/homeIcon.svg';
import { ReactComponent as NotificationIcon } from '../img/icons/messageIcon.svg';
import { ReactComponent as CalendarIcon } from '../img/icons/calendarIcon.svg';
import { ReactComponent as DeleteIcon } from '../img/icons/deleteIcon.svg';
import { ReactComponent as LogoutIcon } from '../img/icons/logoutIcon.svg';
import { ReactComponent as RefreshIcon } from '../img/icons/refreshIcon.svg';
import { ReactComponent as PencilIcon } from '../img/icons/pencilIcon.svg';
import { ReactComponent as LatestIcon } from  '../img/icons/latestIcon.svg';

const SideMenu = ({ isOpen, toggleMenu }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigation = (path) => {
        navigate(path);
        if (window.innerWidth < 1000) {
            toggleMenu();
        }
    };

    const isActive = (itemPath) => {
        if (itemPath === '/') {
            return (
                location.pathname === '/' ||
                location.pathname.startsWith('/publication') ||
                location.pathname.startsWith('/post')
            );
        } else if (itemPath === '/generation') {
            return (
                location.pathname.startsWith('/generation') ||
                location.pathname.startsWith('/story') ||
                location.pathname.startsWith('/insta-post')
            );
        } else {
            return location.pathname.startsWith(itemPath);
        }
    };

    const menuItems = [
        { path: '/', label: 'Dashboard', icon: <HomeIcon className="sideMenu-icon" /> },
        { path: '/latest-posts', label: 'Latest posts', icon: <LatestIcon className="sideMenu-icon" /> },
        { path: '/regenerated', label: 'Regenerated', icon: <RefreshIcon className="sideMenu-icon" /> },
        { path: '/generation', label: 'Generation', icon: <PencilIcon className="sideMenu-icon" /> },
        { path: '/deleted', label: 'Deleted', icon: <DeleteIcon className="sideMenu-icon" /> },
        { path: '/notifications', label: 'Notifications', icon: <NotificationIcon className="sideMenu-icon" /> },
        { path: '/calendar', label: 'Calendar', icon: <CalendarIcon className="sideMenu-icon" /> },
    ];

    const handleLogout = () => {
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('userType');
        window.location.reload();
    };

    return (
        <>
            {isOpen && window.innerWidth < 1000 && (
                <div className="overlay" onClick={toggleMenu}></div>
            )}

            <aside className={`sideMenu ${isOpen ? 'open' : 'closed'}`}>
                <div>
                    <div className="sideMenu-logoContainer">
                        <img src={logo} alt="verita news logo" onClick={() => handleNavigation('/')} />
                    </div>
                    <div className="sideMenu-buttonsContainer">
                        {menuItems.map((item, index) => (
                            <div
                                key={index}
                                className={`sideMenu-buttonWrapper ${isActive(item.path) ? 'active' : ''}`}
                                onClick={() => handleNavigation(item.path)}
                            >
                                {item.icon}
                                <p>{item.label}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="sideMenu-logoutWrapper" onClick={handleLogout}>
                    <LogoutIcon className="sideMenu-icon" />
                    <p>Wyloguj</p>
                </div>
            </aside>
        </>
    );
};

export default SideMenu;
