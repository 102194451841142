import React from 'react';
import '../scss/deletedDashboard.css';


const DeletedDashboard = () => {


    return (
        <section className="deleteddashboard-container">

          <p>
            Nie masz uprawnień
        </p>
            <p>Do tego kanału</p>
        </section>
    );
};

export default DeletedDashboard;
