import '../scss/loader.css';
const Loader = () => {

    return(
        <div className="loader-wrapper">
            <div className="loader"></div>
        </div>
    );
};

export default Loader;
