import React, {useState, useEffect} from 'react';
import '../scss/dashboard.css';
import {ReactComponent as CopyIcon} from "../img/icons/copyIcon.svg";
import {ReactComponent as PencilIcon} from "../img/icons/pencilIcon.svg";
import Loader from "./Loader";
import {useNavigate} from "react-router-dom";
import '../scss/customToast.css';
// import { toast } from 'react-toastify';

const LatestDashboard = ({authData}) => {
    const [data, setData] = useState(null);
    const token = authData.token;
    const navigate = useNavigate();
    const latestPublications = data ? data.latest : [];

    const handlePostClick = (id) => {
        navigate(`/post/${id}`);
    }


    useEffect(() => {
        if (!token) {
            return;
        }

        fetch('https://admin-service.verita.news/', {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.status === 403) {
                    handleLogout();
                    return;
                }
                if (!response.ok) {
                    throw new Error('Błąd sieci');
                }
                return response.json();
            })
            .then(data => setData(data))
            .catch(error => console.error('Błąd podczas pobierania danych:', error));
    }, [token]);

    const handleLogout = () => {
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('userType');
        window.location.reload();
    };


    const categoryData = {
        NEWS: {
            text: "Wydarzenia",
        },
        SPORT: {
            text: "Sport",
        },
        POLITICS: {
            text: "Polityka",
        },
        BUSINESSANDFINANCE: {
            text: "Biznes",
        }
    };

    const getImportance = (numberOfSources) => {
        if (numberOfSources < 9) {
            return {level: 'standard', text: 'Standardowe'};
        } else if (numberOfSources >= 10 && numberOfSources <= 16) {
            return {level: 'important', text: 'Ważne'};
        } else if (numberOfSources > 16) {
            return {level: 'urgent', text: 'Pilne'};
        } else {
            return {level: 'standard', text: 'standardowe'};
        }
    };

    const copyToClipboard = (id) => {
        navigator.clipboard.writeText(id)
            .then(() => {
                alert(`Skopiowano ID: ${id}`);
            })
            .catch(err => {
                console.error(err);
            });
    };

    const handleGenerationClick = (id) => {

        navigate(`/generation/${id}`);
    }


    if (!data) {
        return (
            <div className="dashboard-loader-wrapper">
                <Loader/>
            </div>
        );
    };



    return (
        <section className="dashboard-container">
            <div className="dashboard-container-unpublished">
                <h3>Ostatnio dodane publikacje</h3>
                <ul>
                    {latestPublications.map((pub) => {
                        const category = pub.category;
                        const categoryInfo = categoryData[category] || {text: category, color: '#000000'};
                        const categoryClassName = `category-${category.toLowerCase()}`;

                        const numberOfSources = pub.numberOfSources || 0;
                        const importanceInfo = getImportance(numberOfSources);
                        const importanceClassName = `importance-${importanceInfo.level}`;
                        const isFirstElement = pub.itemId === latestPublications[0].itemId;

                        return (
                            <React.Fragment key={pub.itemId}>
                                {isFirstElement && <div className="extra-separator"></div>}
                                <li key={pub.itemId} onClick={() => handlePostClick(pub.itemId)}>
                                    <div className="text-flags-wrapper">
                                        <h4>{pub.title}</h4>
                                        <div className="flag-container">
                                            <span className={`category ${categoryClassName}`}>{categoryInfo.text}</span><br/>
                                            <span
                                                className={`importance ${importanceClassName}`}>{importanceInfo.text}</span><br/>
                                        </div>
                                    </div>
                                    <div className="dashboard-icons-wrapper">
                                        <div className="separator-small"></div>
                                        <PencilIcon className="dashboard-icon pencilIcon" onClick={(event) => {
                                            event.stopPropagation();
                                            handleGenerationClick(pub.itemId);
                                        }}/>
                                        <CopyIcon
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                copyToClipboard(pub.itemId);
                                            }}
                                            className="dashboard-icon copyIcon"
                                        />
                                    </div>
                                </li>
                                <div className="separator"></div>
                            </React.Fragment>
                        );
                    })}
                </ul>
            </div>
        </section>
    );
};

export default LatestDashboard;
