import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useParams } from 'react-router-dom';
import '../scss/customToast.css';
import '../scss/storyForm.css';
import Loader from './Loader';
import { toast } from 'react-toastify';

function InstaTiktokStoryForm({ authData }) {
    const { id } = useParams();
    const token = authData.token;
    const [title, setTitle] = useState('');
    const [firstPoint, setFirstPoint] = useState('');
    const [secondPoint, setSecondPoint] = useState('');
    const [red, setRed] = useState(false);
    const [instagram, setInstagram] = useState(false);
    const [firstLineBold, setFirstLineBold] = useState(false);
    const [secondLineBold, setSecondLineBold] = useState(false);
    const [sources, setSources] = useState([]);
    const [selectedSource, setSelectedSource] = useState(null);
    const [loading, setLoading] = useState(true);
    const [summary, setSummary] = useState('');
    const [slidesPerView, setSlidesPerView] = useState(4);
    const [generating, setGenerating] = useState(false);

    useEffect(() => {
        if (!token) return;

        fetch(`https://admin-service.verita.news/generate/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.status === 403) {
                    handleLogout();
                    return;
                }
                if (!response.ok) {
                    throw new Error('Error fetching story data');
                }
                return response.json();
            })
            .then(data => {
                const sourcesData = data.sources.map(item => ({
                    sourceId: item.sourceId,
                    sourceName: item.sourceName,
                    sourceImageUrl: item.sourceImageUrl,
                }));
                setSources(sourcesData);
                setLoading(false);

                setTitle(data.post.title);
                setSummary(data.post.summary);

                const points = data.post.summary
                    .split('\n')
                    .filter(line => line.startsWith('->'))
                    .map(line => line.replace('->', '').trim());
                setFirstPoint(points[0] || '');
                setSecondPoint(points[1] || '');
            })
            .catch(error => {
                toast('Coś poszło nie tak', {
                    className: 'toast-error',
                    icon: false,
                });
                setLoading(false);
            });
    }, [id, token]);

    useEffect(() => {
        const updateSlidesPerView = () => {
            if (window.innerWidth < 1000) {
                setSlidesPerView(1);
            } else {
                setSlidesPerView(4);
            }
        };

        updateSlidesPerView();

        window.addEventListener('resize', updateSlidesPerView);
        return () => {
            window.removeEventListener('resize', updateSlidesPerView);
        };
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!selectedSource) {
            toast('Prosze wybrać źródło', {
                className: 'toast-error',
                icon: false,
            });
            return;
        }

        const payload = {
            title,
            firstPoint,
            secondPoint,
            instagram,
            red,
            firstLineBold,
            secondLineBold,
        };

        setGenerating(true);

        const fetchPromise = fetch(`https://admin-service.verita.news/generate/instatiktok/${selectedSource.sourceId}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        }).then(response => {
            console.log(payload);
            if (response.status === 403) {
                handleLogout();
            }
            if (!response.ok) {
                throw new Error('Błąd podczas generowania story');
            }
            return response.blob();
        });

        toast.promise(
            fetchPromise,
            {
                pending: {
                    render: 'Poczekaj chwilke...',
                    className: 'toast-pending',
                    icon: true
                },
                success: {
                    render: 'Pobieranie zakończone',
                    className: 'toast-schedule',
                    icon: false
                },
                error: {
                    render: 'Coś poszło nie tak',
                    className: 'toast-error',
                }
            }
        ).then(blob => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'instagram.png';
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).finally(() => {
            setGenerating(false);
        });
    };

    const handleLogout = () => {
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('userType');
        window.location.reload();
    };
    const handleSourceSelect = (source) => {
        setSelectedSource(source);
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <section className="storyForm-container">
            <form onSubmit={handleSubmit}>
                <div className="form-element">
                    <label>Tytuł:</label>
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </div>

                <div className="form-element">
                    <label>Pierwszy Punkt:</label>
                    <textarea
                        value={firstPoint}
                        onChange={(e) => setFirstPoint(e.target.value)}
                        required
                    ></textarea>
                </div>

                <div className="form-element">
                    <label>Drugi Punkt</label>
                    <textarea
                        value={secondPoint}
                        onChange={(e) => setSecondPoint(e.target.value)}
                        required
                    ></textarea>
                </div>

                <div className="form-checkboxes">
                    <div className="checkbox-wrapper">
                        <label>Instagram - czy na instagrama (jak nie to TikTok)</label>
                        <input
                            type="checkbox"
                            checked={instagram}
                            onChange={(e) => setInstagram(e.target.checked)}
                        />
                    </div>

                    <div className="checkbox-wrapper">
                        <label>Pilne - czy zmieniamy motyw na czerwony?</label>
                        <input
                            type="checkbox"
                            checked={red}
                            onChange={(e) => setRed(e.target.checked)}
                        />
                    </div>

                    <div className="checkbox-wrapper">
                        <label>Pierwszy punkt - czy pogrubiona jest pierwsza linia tekstu?</label>
                        <input
                            type="checkbox"
                            checked={firstLineBold}
                            onChange={(e) => setFirstLineBold(e.target.checked)}
                        />
                    </div>
                    <div className="checkbox-wrapper">
                        <label>Drugi punkt - czy pogrubiona jest pierwsza linia tekstu?</label>
                        <input
                            type="checkbox"
                            checked={secondLineBold}
                            onChange={(e) => setSecondLineBold(e.target.checked)}
                        />
                    </div>
                </div>

                <div className="swiper-container">
                    <h3>Wybierz Źródło:</h3>
                    {sources.length > 0 ? (
                        <Swiper
                            slidesPerView={slidesPerView}
                            spaceBetween={20}
                        >
                            {sources.map((source, index) => (
                                <SwiperSlide key={index}>
                                    <div
                                        onClick={() => handleSourceSelect(source)}
                                    >
                                        <img src={source.sourceImageUrl} alt={source.sourceName} className={`swiper-slide-content ${selectedSource === source ? 'active' : ''}`} />
                                        <p className="source-name">{source.sourceName}</p>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    ) : (
                        <Loader/>
                    )}
                </div>
                <div className="button-container">
                    <button type="submit" disabled={generating}>Generuj Story</button>
                </div>
            </form>

            <div className="publication-details">
                <div className="form-element">
                    <label>Tytuł Publikacji:</label>
                    <input
                        type="text"
                        value={title}
                        readOnly
                    />
                </div>

                <div className="form-element">
                    <label>Podsumowanie:</label>
                    <textarea
                        value={summary}
                        readOnly
                    ></textarea>
                </div>
            </div>
        </section>
    );
}

export default InstaTiktokStoryForm;
