import React, { useEffect, useState } from 'react';
// import {useNavigate} from "react-router-dom";

const GoogleLogin = ({setAuthData}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        console.log(params);
        const code = params.get('code');
        console.log(code)

        if (code) {
            fetch('https://admin-service.verita.news/login/google', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ code })
            })
                .then(async (res) => {
                    if (!res.ok) {
                        const errorText = await res.text();
                        throw new Error(errorText || 'error');
                    }
                    return res.json();
                })
                .then(data => {
                    sessionStorage.setItem('authToken', data.token);
                    setAuthData({
                        token: data.token,
                        userType: data.userType,
                    })
                    setLoading(false);
                    // navigate('/', {replace: true})

                })
                .catch(err => {
                    setError(err.message);
                    setLoading(false);
                });
        } else {
            setError('error');
            setLoading(false);
        }
    }, []);

    //TODO
    if (loading) {
        return <div>...</div>;
    }

    return (
        <>
        </>
    );
};

export default GoogleLogin;
