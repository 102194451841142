import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import '../scss/publicationDetails.css';
import '../scss/customToast.css';
import Loader from "./Loader";
import {toast} from "react-toastify";

function PublicationDetails({authData}) {
    const {id} = useParams();
    const navigate = useNavigate();
    const [publication, setPublication] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        title: '', summary: '', stock: '', youtubeUrl: '', twitterId: '', fullPost: false, category: '',
    });
    const [scheduleTime, setScheduleTime] = useState('');
    const token = authData.token;

    const getImportance = (numberOfSources) => {
        if (numberOfSources < 9) {
            return {level: 'standard', text: 'Standardowe'};
        } else if (numberOfSources >= 10 && numberOfSources <= 16) {
            return {level: 'important', text: 'Ważne'};
        } else if (numberOfSources > 16) {
            return {level: 'urgent', text: 'Pilne'};
        } else {
            return {level: 'standard', text: 'Standardowe'};
        }
    };

    useEffect(() => {
        if (!token) return;

        fetch(`https://admin-service.verita.news/publication/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.status === 403) {
                    handleLogout();
                    return;
                }
                if (!response.ok) {
                    throw new Error('Błąd podczas pobierania publikacji');
                }
                return response.json();
            })
            .then(data => {
                setPublication(data.pub);
                setFormData({
                    title: data.pub.title || '',
                    summary: data.pub.summary || '',
                    stock: data.pub.stock || '',
                    youtubeUrl: data.pub.youtubeUrl || '',
                    twitterId: data.pub.twitterId || '',
                    fullPost: data.pub.fullPost || false,
                    category: data.pub.category || '',
                });
            })
            .catch(error => console.error('Błąd podczas pobierania publikacji:', error));
    }, [id, token]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({
            ...prevState, [name]: name === 'fullPost' ? value === 'true' : value,
        }));
    };

    const handleUpdate = (e) => {
        e.preventDefault();
        setIsLoading(true);

        const cleanedFormData = { ...formData };
        Object.keys(cleanedFormData).forEach((key) => {
            if (cleanedFormData[key] === '') {
                delete cleanedFormData[key];
            }
        });

        const fetchPromise = fetch(`https://admin-service.verita.news/publication/${id}/update`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(cleanedFormData),
        })
            .then(response => {
                if (response.status === 403) {
                    handleLogout();
                    return Promise.reject();
                }
                if (!response.ok) {
                    return Promise.reject();
                }
                return response.text();
            })
            .finally(() => setIsLoading(false));

        toast.promise(
            fetchPromise,
            {
                success: { render: 'Post został zaktualizowany', className: 'toast-update', icon: false },
                pending: { render: 'Poczekaj chwilkę...', className: 'toast-pending', icon: true },
                error: { render: 'Coś poszło nie tak', className: 'toast-error' },
            }
        ).catch(() => {});
    };

    const handleRegenerate = () => {
        setIsLoading(true);
        const fetchPromise = fetch(`https://admin-service.verita.news/publication/${id}/regenerate`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.status === 403) {
                    handleLogout();
                    return Promise.reject();
                }
                if (!response.ok) {
                    return Promise.reject();
                }
            })
            .finally(() => setIsLoading(false));

        toast.promise(
            fetchPromise,
            {
                success: { render: 'Post zostanie wygenerowany ponownie', className: 'toast-regenerate', icon: false },
                pending: { render: 'Poczekaj chwilkę...', className: 'toast-pending', icon: true },
                error: { render: 'Coś poszło nie tak', className: 'toast-error' },
            }
        ).catch(() => {});
    };

    const handlePublish = () => {
        setIsLoading(true);
        const cleanedFormData = { ...formData };
        Object.keys(cleanedFormData).forEach((key) => {
            if (cleanedFormData[key] === '') {
                delete cleanedFormData[key];
            }
        });

        const fetchPromise = fetch(`https://admin-service.verita.news/publication/${id}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(cleanedFormData),
        })
            .then(response => {
                if (response.status === 403) {
                    handleLogout();
                    return Promise.reject();
                }
                if (!response.ok) {
                    return Promise.reject();
                }
                navigate('/');
            })
            .finally(() => setIsLoading(false));

        toast.promise(
            fetchPromise,
            {
                success: { render: 'Publikacja została opublikowana', className: 'toast-publish', icon: false },
                pending: { render: 'Poczekaj chwilkę...', className: 'toast-pending', icon: true },
                error: { render: 'Coś poszło nie tak', className: 'toast-error' },
            }
        ).catch(() => {});
    };

    const handleDelete = () => {
        if (!window.confirm('Czy na pewno chcesz usunąć tę publikację?')) return;
        setIsLoading(true);

        const fetchPromise = fetch(`https://admin-service.verita.news/publication/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.status === 403) {
                    handleLogout();
                    return Promise.reject();
                }
                if (!response.ok) {
                    return Promise.reject();
                }
                navigate('/');
            })
            .finally(() => setIsLoading(false));

        toast.promise(
            fetchPromise,
            {
                success: { render: 'Publikacja została usunięta', className: 'toast-delete', icon: false },
                pending: { render: 'Poczekaj chwilkę...', className: 'toast-pending', icon: true },
                error: { render: 'Coś poszło nie tak', className: 'toast-error' },
            }
        ).catch(() => {});
    };

    const handleSchedule = () => {
        if (!scheduleTime) {
            toast.warning('Proszę wprowadzić czas do zaplanowania posta.');
            return;
        }
        setIsLoading(true);

        const fetchPromise = fetch(`https://admin-service.verita.news/publication/${id}/schedule?time=${scheduleTime}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.status === 403) {
                    handleLogout();
                    return Promise.reject();
                }
                if (!response.ok) {
                    return Promise.reject();
                }
                navigate('/');
            })
            .finally(() => setIsLoading(false));

        toast.promise(
            fetchPromise,
            {
                success: { render: 'Publikacja została zaplanowana', className: 'toast-schedule', icon: false },
                pending: { render: 'Poczekaj chwilkę...', className: 'toast-pending', icon: true },
                error: { render: 'Coś poszło nie tak', className: 'toast-error' },
            }
        ).catch(() => {});
    };

    const handleLogout = () => {
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('userType');
        window.location.reload();
    };

    if (!publication) {
        return <Loader/>;
    }

    const numberOfSources = publication.newsBucket.numberOfSources;
    const importanceInfo = getImportance(numberOfSources);
    const importanceClassName = `stats-wrapper-${importanceInfo.level}`;

    return (
        <section className="publicationDetails-container">
            <div className="stats-container">
                <div className="stats-wrapper stats-wrapper-mint">
                    <p className="stats-heading">ID Publikacji</p>
                    <p className="stats-value">{publication.publicationId}</p>
                </div>
                <div className={`stats-wrapper ${importanceClassName}`}>
                    <p className="stats-heading">Liczba artykułów</p>
                    <p className="stats-value">{publication.newsBucket.numberOfSources}</p>
                </div>
            </div>
            <form onSubmit={handleUpdate}>
                <div className="form-firstPart-wrapper">
                    <div className="form-firstPart-part right">
                        <div className="form-input-wrapper">
                            <label>Tytuł:</label>
                            <input
                                type="text"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                disabled={isLoading}
                            />
                        </div>
                        <div className="form-input-wrapper">
                            <label>Stock Symbols:</label>
                            <input
                                type="text"
                                name="stock"
                                value={formData.stock}
                                onChange={handleChange}
                                disabled={isLoading}
                            />
                        </div>
                        <div className="form-input-wrapper">
                            <label>YouTube URL:</label>
                            <input
                                type="text"
                                name="youtubeUrl"
                                value={formData.youtubeUrl}
                                onChange={handleChange}
                                disabled={isLoading}
                            />
                        </div>
                        <div className="form-input-wrapper">
                            <label>Twitter ID:</label>
                            <input
                                type="text"
                                name="twitterId"
                                value={formData.twitterId}
                                onChange={handleChange}
                                disabled={isLoading}
                            />
                        </div>
                    </div>
                    <div className="form-firstPart-part left">
                        <div className="fieldset-container">
                            <label className="fieldset-label">Kategoria</label>
                            <fieldset disabled={isLoading}>
                                <label>
                                    <input
                                        type="radio"
                                        name="category"
                                        value="NEWS"
                                        checked={formData.category === 'NEWS'}
                                        onChange={handleChange}
                                    />
                                    <span className="radio-mark news"></span>
                                    <span className="category-text">NEWS</span>
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="category"
                                        value="POLITICS"
                                        checked={formData.category === 'POLITICS'}
                                        onChange={handleChange}
                                    />
                                    <span className="radio-mark politics"></span>
                                    <span className="category-text">POLITICS</span>
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="category"
                                        value="SPORT"
                                        checked={formData.category === 'SPORT'}
                                        onChange={handleChange}
                                    />
                                    <span className="radio-mark sport"></span>
                                    <span className="category-text">SPORT</span>
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="category"
                                        value="BUSINESSANDFINANCE"
                                        checked={formData.category === 'BUSINESSANDFINANCE'}
                                        onChange={handleChange}
                                    />
                                    <span className="radio-mark business"></span>
                                    <span className="category-text">BUSINESS</span>
                                </label>
                            </fieldset>
                        </div>
                        <div className="fieldset-container">
                            <label className="fieldset-label">Pełny post</label>
                            <fieldset disabled={isLoading}>
                                <label>
                                    <input
                                        type="radio"
                                        name="fullPost"
                                        value="true"
                                        checked={formData.fullPost === true}
                                        onChange={handleChange}
                                    />
                                    <span className="radio-mark mint"></span>
                                    <span className="category-text">TAK</span>
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="fullPost"
                                        value="false"
                                        checked={formData.fullPost === false}
                                        onChange={handleChange}
                                    />
                                    <span className="radio-mark mint"></span>
                                    <span className="category-text">NIE</span>
                                </label>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div className="summary-container">
                    <div className="form-input-wrapper">
                        <label>Streszczenie:</label>
                        <textarea
                            name="summary"
                            value={formData.summary}
                            onChange={handleChange}
                            disabled={isLoading}
                        />
                    </div>
                </div>
                <div className="form-button-container">
                    <button type="button" className="regenerate" onClick={handleRegenerate} disabled={isLoading}>Wygeneruj ponownie</button>
                    <button type="submit" className="update" disabled={isLoading}>Zaktualizuj</button>
                    <button type="button" onClick={handlePublish} disabled={isLoading}>Opublikuj</button>
                </div>
            </form>
            <div className="delete-button-container">
                <button type="button" onClick={handleDelete} disabled={isLoading}>Usuń</button>
            </div>
            <div className="schedule-container">
                <label>Zaplanuj publikacje (1055 = 10:55 | Do 5 min. czyli 10,15,20,25...</label>
                <div className="schedule-wrapper">
                    <input
                        type="text"
                        value={scheduleTime}
                        onChange={(e) => setScheduleTime(e.target.value)}
                        disabled={isLoading}
                    />
                    <button type="button" onClick={handleSchedule} disabled={isLoading}>Zaplanuj Post</button>
                </div>
            </div>
        </section>
    );
}

export default PublicationDetails;
