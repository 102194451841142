import React, { useState } from 'react';
import { toast } from 'react-toastify';
import '../scss/notificationDashboard.css';

const NotificationDashboard = ({ authData }) => {
    const [postId, setPostId] = useState('');
    const [loading, setLoading] = useState(false);

    if (authData.userType !== 'ADMIN') {
        return (
            <section className="notifications-error-page">
                <p className="permission-text">
                    Nie masz uprawnień
                </p>
                <p className="permission-text">Do tego kanału</p>
            </section>

            )
    }

    const handleNotify = async () => {
        if (!postId.trim()) {
            toast('Proszę podać ID', {
                className: 'toast-error',
                icon: false,

            });
            return;
        }

        setLoading(true);

        try {
            const response = await fetch(`https://admin-service.verita.news/post/${postId}/notify?topic=Important`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authData.token}`,
                },
            });

            if (response.ok) {
                toast('Notyfikacja została wysłana', {
                    className: 'toast-publish',
                    icon: false,
                });
            } else if (response.status === 403) {
                toast('Coś poszło nie tak', {
                    className: 'toast-error',
                    icon: false,
                });
            } else {
                const errorText = await response.text();
                toast(`Błąd: ${errorText || response.statusText}`);
            }
        } catch (error) {
            console.error('Błąd podczas wysyłania notyfikacji:', error);
            toast('Coś poszło nie tak', {
                className: 'toast-error',
                icon: false,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className="notifications-page">
            <h3>Wyślij notyfikację</h3>
            <div className="notification-form">
                <input
                    type="text"
                    placeholder="Wprowadź ID postu"
                    value={postId}
                    onChange={(e) => setPostId(e.target.value)}
                />
                <button onClick={handleNotify} disabled={loading}>
                    {loading ? 'Wysyłanie...' : 'Wyślij'}
                </button>
            </div>
        </section>
    );
};

export default NotificationDashboard;
