import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../scss/publicationDetails.css';
import Loader from './Loader';
import { toast } from "react-toastify";
import '../scss/customToast.css';

function PostDetails({ authData }) {
    const { id } = useParams();
    const navigate = useNavigate();
    const [post, setPost] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        summary: '',
        stock: '',
        youtubeUrl: '',
        twitterId: '',
        fullPost: false,
        category: '',
    });
    const token = authData.token;

    useEffect(() => {
        if (!token) return;
        fetch(`https://admin-service.verita.news/post/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.status === 403) {
                    handleLogout();
                    return;
                }
                if (!response.ok) {
                    throw new Error('Błąd podczas pobierania posta');
                }
                return response.json();
            })
            .then(data => {
                setPost(data);
                setFormData({
                    title: data.title || '',
                    summary: data.summary || '',
                    stock: data.stock || '',
                    youtubeUrl: data.youtubeUrl || '',
                    twitterId: data.twitterId || '',
                    fullPost: data.fullPost || false,
                    category: data.category || '',
                });
            })
            .catch(error => console.error('Błąd podczas pobierania posta:', error));
    }, [id, token]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: name === 'fullPost' ? value === 'true' : value,
        }));
    };

    const handleUpdate = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const cleanedFormData = { ...formData };
        Object.keys(cleanedFormData).forEach((key) => {
            if (cleanedFormData[key] === '') {
                delete cleanedFormData[key];
            }
        });

        const fetchPromise = fetch(`https://admin-service.verita.news/post/${id}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(cleanedFormData),
        })
            .then(response => {
                if (response.status === 403) {
                    handleLogout();
                    return Promise.reject();
                }
                if (!response.ok) {
                    return response.text().then(text => {
                        console.error('Błąd podczas aktualizacji posta:', response.status, text);
                        return Promise.reject();
                    });
                }
                return response.text();
            })
            .finally(() => {
                setIsLoading(false);
            });

        toast.promise(
            fetchPromise,
            {
                success: { render: 'Post został zaktualizowany', className: 'toast-update', icon: false },
                pending: { render: 'Poczekaj chwilkę...', className: 'toast-pending', icon: true },
                error: { render: 'Coś poszło nie tak', className: 'toast-error' },
            }
        ).catch(() => {});
    };

    const handleRegenerate = () => {
        setIsLoading(true);
        const fetchPromise = fetch(`https://admin-service.verita.news/post/${id}/regenerate`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.status === 403) {
                    handleLogout();
                    return Promise.reject();
                }
                if (!response.ok) {
                    return Promise.reject();
                }
            })
            .finally(() => {
                setIsLoading(false);
            });

        toast.promise(
            fetchPromise,
            {
                success: { render: 'Post zostanie wygenerowany ponownie', className: 'toast-regenerate', icon: false },
                pending: { render: 'Poczekaj chwilkę...', className: 'toast-pending', icon: true },
                error: { render: 'Coś poszło nie tak', className: 'toast-error' },
            }
        ).catch(() => {});
    };

    const handleDelete = () => {
        if (!window.confirm('Czy na pewno chcesz usunąć ten post?')) return;
        setIsLoading(true);
        const fetchPromise = fetch(`https://admin-service.verita.news/post/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.status === 403) {
                    handleLogout();
                    return Promise.reject();
                }
                if (!response.ok) {
                    return Promise.reject();
                }
                navigate('/');
            })
            .finally(() => {
                setIsLoading(false);
            });

        toast.promise(
            fetchPromise,
            {
                success: { render: 'Post został usunięty', className: 'toast-delete', icon: false },
                pending: { render: 'Poczekaj chwilkę...', className: 'toast-pending', icon: true },
                error: { render: 'Coś poszło nie tak', className: 'toast-error' },
            }
        ).catch(() => {});
    };

    const handleLogout = () => {
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('userType');
        window.location.reload();
    };

    if (!post) {
        return <Loader />;
    }

    const numberOfSources = post.numberOfSources;
    const getImportance = (numberOfSources) => {
        if (numberOfSources < 9) {
            return { level: 'standard', text: 'Standardowe' };
        } else if (numberOfSources >= 10 && numberOfSources <= 16) {
            return { level: 'important', text: 'Ważne' };
        } else if (numberOfSources > 16) {
            return { level: 'urgent', text: 'Pilne' };
        } else {
            return { level: 'standard', text: 'Standardowe' };
        }
    };
    const importanceInfo = getImportance(numberOfSources);
    const importanceClassName = `stats-wrapper-${importanceInfo.level}`;

    return (
        <section className="publicationDetails-container">
            <div className="stats-container">
                <div className="stats-wrapper stats-wrapper-mint">
                    <p className="stats-heading">ID Posta</p>
                    <p className="stats-value">{post.itemId}</p>
                </div>
                <div className={`stats-wrapper ${importanceClassName}`}>
                    <p className="stats-heading">Liczba artykułów</p>
                    <p className="stats-value">{post.numberOfSources}</p>
                </div>
            </div>
            <form onSubmit={handleUpdate}>
                <div className="form-firstPart-wrapper">
                    <div className="form-firstPart-part right">
                        <div className="form-input-wrapper">
                            <label>Tytuł:</label>
                            <input
                                type="text"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                disabled={isLoading}
                            />
                        </div>
                        <div className="form-input-wrapper">
                            <label>Stock Symbols:</label>
                            <input
                                type="text"
                                name="stock"
                                value={formData.stock}
                                onChange={handleChange}
                                disabled={isLoading}
                            />
                        </div>
                        <div className="form-input-wrapper">
                            <label>YouTube URL:</label>
                            <input
                                type="text"
                                name="youtubeUrl"
                                value={formData.youtubeUrl}
                                onChange={handleChange}
                                disabled={isLoading}
                            />
                        </div>
                        <div className="form-input-wrapper">
                            <label>Twitter ID:</label>
                            <input
                                type="text"
                                name="twitterId"
                                value={formData.twitterId}
                                onChange={handleChange}
                                disabled={isLoading}
                            />
                        </div>
                    </div>
                    <div className="form-firstPart-part left">
                        <div className="fieldset-container">
                            <label className="fieldset-label">Kategoria</label>
                            <fieldset disabled={isLoading}>
                                <label>
                                    <input
                                        type="radio"
                                        name="category"
                                        value="NEWS"
                                        checked={formData.category === 'NEWS'}
                                        onChange={handleChange}
                                    />
                                    <span className="radio-mark news"></span>
                                    <span className="category-text">NEWS</span>
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="category"
                                        value="POLITICS"
                                        checked={formData.category === 'POLITICS'}
                                        onChange={handleChange}
                                    />
                                    <span className="radio-mark politics"></span>
                                    <span className="category-text">POLITICS</span>
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="category"
                                        value="SPORT"
                                        checked={formData.category === 'SPORT'}
                                        onChange={handleChange}
                                    />
                                    <span className="radio-mark sport"></span>
                                    <span className="category-text">SPORT</span>
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="category"
                                        value="BUSINESSANDFINANCE"
                                        checked={formData.category === 'BUSINESSANDFINANCE'}
                                        onChange={handleChange}
                                    />
                                    <span className="radio-mark business"></span>
                                    <span className="category-text">BUSINESS</span>
                                </label>
                            </fieldset>
                        </div>
                        <div className="fieldset-container">
                            <label className="fieldset-label">Pełny post</label>
                            <fieldset disabled={isLoading}>
                                <label>
                                    <input
                                        type="radio"
                                        name="fullPost"
                                        value="true"
                                        checked={formData.fullPost === true}
                                        onChange={handleChange}
                                    />
                                    <span className="radio-mark mint"></span>
                                    <span className="category-text">TAK</span>
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="fullPost"
                                        value="false"
                                        checked={formData.fullPost === false}
                                        onChange={handleChange}
                                    />
                                    <span className="radio-mark mint"></span>
                                    <span className="category-text">NIE</span>
                                </label>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div className="summary-container">
                    <div className="form-input-wrapper">
                        <label>Streszczenie:</label>
                        <textarea
                            name="summary"
                            value={formData.summary}
                            onChange={handleChange}
                            disabled={isLoading}
                        />
                    </div>
                </div>
                <div className="form-button-container">
                    <button type="button" className="regenerate" onClick={handleRegenerate} disabled={isLoading}>
                        Wygeneruj ponownie
                    </button>
                    <button type="submit" className="update" disabled={isLoading}>
                        Zaktualizuj
                    </button>
                </div>
            </form>
            <div className="delete-button-container">
                <button type="button" onClick={handleDelete} disabled={isLoading}>
                    Usuń
                </button>
            </div>
        </section>
    );
}

export default PostDetails;
