import '../scss/navbar.css';
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {ReactComponent as PencilIcon} from "../img/icons/pencilIcon.svg";
import {ReactComponent as SearchIcon} from "../img/icons/searchIcon.svg";

const Navbar = ({userType, toggleMenu}) => {
    const [articleId, setArticleId] = useState("");
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        setArticleId(e.target.value);
    };
    const handleSearch = () => {
        if (articleId) {
            navigate(`/post/${articleId}`);
        }
    };
    const handleEdit = () => {
        if (articleId) {
            navigate(`/generation/${articleId}`);
        }
    };
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    };

    return(
        <nav>
            <div className="navbar-container">
                <h2>Permisja: <span>{userType}</span></h2>
                <div className="hamburger" onClick={toggleMenu} style={{color: "white"}}>
                    <div className="hamburger-bar"></div>
                    <div className="hamburger-bar"></div>
                    <div className="hamburger-bar"></div>
                </div>
                <div className="navbar-search">
                    <input
                        type="text"
                        placeholder="Wpisz ID artykułu"
                        value={articleId}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                        className="navbar-input"
                    />
                    <div className="navbar-icons-wrapper">
                        <PencilIcon onClick={handleEdit} className="pencil-icon"/>
                        <div className="separator"></div>
                        <SearchIcon onClick={handleSearch} className="search-icon"/>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;